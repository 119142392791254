import React from 'react';
import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Pressable } from 'react-native';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import BlurView from '../../basic/BlurView/BlurView';
import { space } from '../../../../themes/new/theme';
import { Appearance } from '../../../../themes/new/helper';

const StyledView = styled.View`
  ${(props) => props.theme.boxShadows.sm}
  backgroundColor:${({ bgAppearance, level, theme }) =>
    // eslint-disable-next-line no-nested-ternary
    bgAppearance === 'filled'
      ? theme.colors.primary[10]
      : level === '1'
      ? theme.colors.opacity[95]
      : 'transparent'}
`;

const TopNavigation = ({
  title,
  subTitle,
  rightText,
  IconLeft,
  IconRight,
  SecondaryIconRight,
  ChildrenElement,
  appearance,
  level,
  textColor,
  textSize = 'md',
  subTextSize = 'xs',
  rightTextSize = 'xs',
  rightTextColor,
  onPressRightText = () => {},
}) => {
  const RenderText = () => (
    <Text
      size={textSize}
      color={textColor || 'primary.400'}
      numberOfLines={1}
      ellipsizemode="tail"
    >
      {title}
    </Text>
  );

  const RenderSubText = () => (
    <Text
      size={subTextSize}
      color={textColor || 'primary.400'}
      numberOfLines={1}
      ellipsizemode="tail"
    >
      {subTitle}
    </Text>
  );

  const RenderRightText = () => (
    <Pressable
      style={{ position: 'absolute', right: space.xl, height: space['4xl'] }}
      onPress={onPressRightText}
    >
      <Text
        size={rightTextSize}
        color={rightTextColor || 'primary.400'}
        numberOfLines={1}
      >
        {rightText}
      </Text>
    </Pressable>
  );

  return (
    // always render navigation , after the component which we are blurring
    <BlurView level={appearance !== Appearance.GHOST ? 'none' : level}>
      <StyledView level={level} bgAppearance={appearance}>
        <SafeAreaView edges={['top']} />
        <View p="lg">
          <View
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {IconLeft}
            <View alignItems="center">
              <RenderText />
              {subTitle ? <RenderSubText /> : null}
            </View>
            <View flexDirection="row">
              {SecondaryIconRight}
              {IconRight}
            </View>
            {rightText ? <RenderRightText /> : null}
          </View>
        </View>
        {ChildrenElement}
      </StyledView>
    </BlurView>
  );
};

TopNavigation.defaultProps = {
  title: '',
  rightText: '',
  subTitle: '',
  IconLeft: (
    <View width="lg" height="lg" borderWidth="xs" borderColor="transparent" />
  ),
  SecondaryIconRight: <></>,
  IconRight: (
    <View width="lg" height="lg" borderWidth="xs" borderColor="transparent" />
  ),
  appearance: Appearance.FILLED,
  ChildrenElement: <></>,
  level: '1',
};

export default TopNavigation;
